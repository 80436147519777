export enum EVideoPaymentStatus {
    unsolicited = 'unsolicited',
    requested = 'requested',
    paid = 'paid',
    rejected = 'rejected'
}

export enum EReqStatus { 
    denied = 'отклонено',
    approved = 'выполнено',
    requested = 'в ожидании'
}