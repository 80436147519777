import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss'
import { AuthComponent } from './components/AuthComponent/AuthComponent'
import { UsersPage } from './pages/User/User'
import { TracksPage } from './pages/Tracks/Tracks'
import { AuthRoutes } from './components/AuthRoute/AuthRoute'
import { CurrentTrack } from './pages/CurrentTrack/CurrentTrack'
import { VideoPage } from './pages/VideoPage/VideoPage'
import { CurrentUserPage } from './pages/CurrentUser/CurrentUserPage'
import { SubmissionsPage } from './pages/Submissions/SubmissionsPage'
import { BlogPage } from './pages/Blog/Blog'
import { CurrentPostPage } from './pages/CurrentPost/CurrentPost'
import { CreateBlogPage } from './pages/CreateBlog/CreateBlog'

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthRoutes />}>
          <Route path='/' element={<UsersPage />} />
          <Route path='/tracks' element={<TracksPage />} />
          <Route path='/track/:id' element={<CurrentTrack />} />
          <Route path='/video' element={<VideoPage />} />
          <Route path='/video/:status' element={<VideoPage />} />
          <Route path='/user/:id' element={<CurrentUserPage/>}/>
          <Route path='/submissions/:content' element={<SubmissionsPage/>}/>
          <Route path='/blog' element={<BlogPage/>}/>
          <Route path='/blog/:id' element={<CurrentPostPage/>}/>
          <Route path='/create_post' element={<CreateBlogPage/>}/>
        </Route>
        <Route path='/login' element={<AuthComponent />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
