import { useParams } from 'react-router-dom';
import { MainLayoutComponent } from '../../components/layouts/MainLayoutComponent/MainLayoutComponent';
import styles from './CurrentPost.module.scss';
import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getPostById } from '../../redux/slices/posts';


export const CurrentPostPage: FC = () => {

    const { id } = useParams();

    const dispatch = useAppDispatch();
    const { currentPost, isLoading } = useAppSelector((state) => state.posts);

    if (id != undefined) {
        useEffect(() => {
            dispatch(getPostById(+id));
        }, []);
    }

    return <>
        <MainLayoutComponent>
            <div className={styles.container}>
                <div className={styles.post_container}>
                    <div className={styles.header}>
                        <h1>{currentPost?.title}</h1>
                        <img src={currentPost?.preview} />
                    </div>
                    <div className={styles.text_body} dangerouslySetInnerHTML={{ __html: currentPost?.body }} />
                </div>
            </div>
        </MainLayoutComponent>
    </>

}