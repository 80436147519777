import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from 'react';
import styles from "./ContanerForPages.module.scss";
import { MainLayoutComponent } from '../layouts/MainLayoutComponent/MainLayoutComponent';
import { Input, Spin } from 'antd';
import { SearchOutlined } from "@ant-design/icons";

interface ContainerProps {
    title: string;
    icon: any;
    inputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    count: number;
    isLoading: boolean;
    searchTitle: string;
}

export const ContainerForPages: FC<PropsWithChildren<ContainerProps>> = ({ title, icon, inputChange, children, count, isLoading, searchTitle }) => {

    return <>
        <MainLayoutComponent>
            <div className={styles.main_container}>
                <div className={styles.header}>
                    <div>
                        <h1>{title}</h1>
                        {/* <p>Описание</p> */}
                    </div>

                    <div className={styles.users_count_container}>
                        <p>{count} видео</p>
                        {icon}
                    </div>

                </div>

                <div className={styles.search_container}>
                    <Input placeholder={searchTitle} onChange={(e) => inputChange(e)} prefix={<SearchOutlined />} style={{ maxWidth: '300px' }} size='middle' />
                    {
                        isLoading && <Spin/>
                    }
                </div>

                {children}
            </div>
        </MainLayoutComponent>
    </>

}