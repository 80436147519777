import { FC, useEffect } from 'react';
import { MainLayoutComponent } from '../../components/layouts/MainLayoutComponent/MainLayoutComponent';
import styles from './CurrentTrack.module.scss';
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import { Input, Spin } from 'antd';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getTrackInfoById } from '../../redux/slices/tracks';
import { UserItemForCurrentTrack } from '../../components/UserItemForCurrentTrack/UserItemForCurrentTrack';

export const CurrentTrack: FC = () => {

    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { currentTrack, isLoading } = useAppSelector((state) => state.tracks);

    useEffect(() => {
        if (id != undefined) {
            dispatch(getTrackInfoById(Number(id)));
        }
    }, []);

    return <>
        <MainLayoutComponent>
            <div className={styles.track_container}>
                {
                    !isLoading && currentTrack != null? <>
                        <div className={styles.header}>
                            <h1>Трек: {currentTrack.originalName}</h1>

                            <div className={styles.tracks_count_container}>
                                <p>Прикрпелено пользователей: {}</p>
                                <PiSoundcloudLogoDuotone size={25} color={'black'} />
                            </div>
                        </div>

                        <div className={styles.search_container}>
                            <div className={styles.input_search_container}>
                                <Input placeholder="Поиск пользователя" size='middle' style={{ maxWidth: '250px' }} />
                                {
                                    // isLoading && <Spin/>
                                }
                            </div>
                        </div>

                        <div className={styles.tracks_container}>
                            {
                        // currentTrack.users?.length > 0 && currentTrack.users.map((user) => {
                        //     return <UserItemForCurrentTrack user={user} trackId={Number(id)} key={user.id} />
                        // })
                    }
                        </div>
                    </> : <Spin style={{marginTop: '30%'}}/>
                }
            </div>
        </MainLayoutComponent>
    </>

}