import React, { useState } from 'react';
import { SoundOutlined, UserOutlined, SettingOutlined, DollarOutlined, VideoCameraOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import styles from './Menu.module.scss';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../hooks/hooks';
import { setShowModal } from '../../redux/slices/modal';
import { EVideoPaymentStatus } from '../../utils/enums';
import { MdPostAdd } from "react-icons/md";


type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items: MenuProps['items'] = [
    getItem('Пользователи', 'sub1', <UserOutlined />, [
        getItem('Поиск', '1'),
        getItem('Баны', '2'),
        { type: 'divider' },
        getItem('Создать аккаунт', '3'),
    ]),

    getItem('Треки', 'sub2', <SoundOutlined />, [
        getItem('Поиск', '5'),
    ]),

    getItem('Видео', 'sub3', <VideoCameraOutlined />, [
        getItem('Поиск', '9')
    ]),

    getItem('Заявки', 'sub5', <DollarOutlined />, [
        getItem('Вывод средств', 'withdaraw'),
        getItem('Выплаты за контент', 'sub7', null, [
            getItem('Белый контент', 'whiteContent'),
            getItem('Серый контент', 'grayContent'),
        ])
    ]),

    getItem('Блог', 'sub_blog', <MdPostAdd />),

    { type: 'divider' },

    getItem('Аккаунт', 'sub4', <SettingOutlined />, [

    ]),
];

const AdminMenu: React.FC = () => {

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const onClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            case '1':
                navigate('/');
                break;
            case '3':
                dispatch(setShowModal(true));
                break;
            case '5':
                navigate('/tracks');
                break;
            case '9':
                navigate('/video');
                break;
            case '10':
                navigate(`/video/${EVideoPaymentStatus.requested}`);
                break;

            case 'whiteContent':
                navigate(`/submissions/white`);
                break;

            case 'grayContent':
                navigate(`/submissions/gray`);
                break;

            case 'sub_blog':
                navigate(`/blog`);
                break;
        }
    };

    return (
        <Menu className={styles.menu_container}
            onClick={onClick}
            style={{ width: 256 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={items}
        />
    );
};

export default AdminMenu;