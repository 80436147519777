import { ChangeEvent, FC, useState } from 'react';
import styles from './CreateUserModal.module.scss';
import { Button, Input } from 'antd';
import { IoClose } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setShowModal } from '../../../redux/slices/modal';
import { CreateUserDto } from '../../../redux/dto/dto';
import { createNewUser } from '../../../redux/slices/users';


export const CreateUserModal: FC = () => {

    const {isCreateLoading} = useAppSelector((state) => state.users);
    const dispatch = useAppDispatch();

    const [values, setValues] = useState<CreateUserDto>({
        nick: '',
        password: '',
        telegram: ''
    });

    const updateValues = (e: ChangeEvent<HTMLInputElement>) => setValues({...values, [e.target.name]: e.target.value});

    return <>
        <div className={styles.modal} onClick={() => dispatch(setShowModal(false))}>
            <div className={styles.modal_container} onClick={(e) => e.stopPropagation()}>
                <p>Создание пользователя</p>
                <div className={styles.close_icon_container} onClick={() => dispatch(setShowModal(false))}>
                    <IoClose size={25} color='black' className={styles.close_icon} />
                </div>
                <div className={styles.input_container}>
                    <Input placeholder='Логин' name='nick' onChange={(e) => updateValues(e)}/>
                    <Input placeholder='Пароль' name='password' onChange={(e) => updateValues(e)}/>
                    <Input addonBefore="@" placeholder='telegram' name='telegram' onChange={(e) => updateValues(e)}/>
                </div>
                <Button type='primary' loading={isCreateLoading} onClick={() => dispatch(createNewUser(values))}>Создать</Button>
            </div>
        </div>
    </>

}