import { Input, Radio, Spin } from 'antd';
import { MainLayoutComponent } from '../../components/layouts/MainLayoutComponent/MainLayoutComponent';
import styles from './SubmissionsPage.module.scss';
import { FC, useEffect, useState } from 'react';
import { GiMoneyStack } from "react-icons/gi";
import { SearchOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useParams } from 'react-router-dom';
import { getSubmissionsByParams } from '../../redux/slices/submissions';
import { SubmissionsItem } from '../../components/SubmissionItem/SubmissionItem';
import { EReqStatus } from '../../utils/enums';
import { GetReqDto } from '../../redux/dto/dto';
import { ApproveSubmissionModal } from '../../components/modals/ApproveSubmissionModal/ApproveSubmissionModal';



export const SubmissionsPage: FC = () => {

    const { content } = useParams();

    const [status, setStatus] = useState<string>(EReqStatus.requested);
    
    const dispatch = useAppDispatch();
    const { submissios, isLoading } = useAppSelector((state) => state.submissions);

    useEffect(() => {
        dispatch(getSubmissionsByParams({ contentType: content!, status: status }));
    }, [content, status]);

    const updateStatus = (e: string) => setStatus(e);

    return <>
        <MainLayoutComponent>
            <div className={styles.container}>

                <div className={styles.header}>
                    <h1>{content == 'white' ? 'Заявки по белому контенту' : content == 'gray' ? 'Заявки по серому контенту': ''}</h1>

                    <div className={styles.tracks_count_container}>
                        <p>заявки: {submissios?.length}</p>
                        <GiMoneyStack size={25} color={'black'} />
                    </div>
                </div>

                <div className={styles.search_container}>
                    <Input placeholder="Поиск заявки по параметрам" prefix={<SearchOutlined />} style={{ maxWidth: '300px' }} size='middle' />
                    {
                        isLoading && <Spin />
                    }

                    <div className={styles.choise_container}>
                        <Radio.Group defaultValue={EReqStatus.requested} onChange={(e) => updateStatus(e.target.value)}>
                            <Radio.Button value={EReqStatus.approved}>Выполненные</Radio.Button>
                            <Radio.Button value={EReqStatus.requested}>В ожидании</Radio.Button>
                            <Radio.Button value={EReqStatus.denied}>Отклоненные</Radio.Button>
                        </Radio.Group>

                    </div>
                </div>

                <div className={styles.items_container}>
                    {
                        submissios.length > 0 && submissios.map((item) => {
                            return <SubmissionsItem submission={item} key={item.id}/>
                        })
                    }
                </div>
            </div>
        </MainLayoutComponent>
    </>

}