import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IToken, IUserAuth } from "../../types/types"
import paxios, { parseError } from '../../utils/utils';


interface IUserState {
    loading: boolean;
    isLogged: boolean;
}

export const postLogin = createAsyncThunk(
    'login',
    async (user: IUserAuth) => {
        try {
            const { data } = await paxios.post('auth/login', user);
            console.log(data);
            return await data as IToken;
        } catch (error) {
            alert(parseError(error));
        }
    }
)

const initialState: IUserState = {
    loading: false,
    isLogged: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(postLogin.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(postLogin.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.token != undefined) {
                    localStorage.setItem('token', action.payload.token);
                    state.isLogged = true;
                }
            }),
            builder.addCase(postLogin.rejected, (state) => {
                state.loading = false;
            })
    }
});

export default authSlice.reducer;