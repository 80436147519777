import { FC, useState } from 'react';
import styles from './SubmissionItem.module.scss';
import { AiOutlineFieldTime, AiOutlineCheckCircle } from "react-icons/ai";
import { IReq } from '../../types/types';
import { Button, notification } from 'antd';
import { ApproveSubmissionModal } from '../modals/ApproveSubmissionModal/ApproveSubmissionModal';
import { EReqStatus } from '../../utils/enums';
import paxios, { parseError } from '../../utils/utils';


interface SubmissionItemProps {
    submission: IReq
}


export const SubmissionsItem: FC<SubmissionItemProps> = ({ submission }) => {

    const [openModal, setOpenModal] = useState<boolean>(false);

    const rejectedSubmission = async () => {
        try {
            await paxios.post(`receiving-funds/rejected/${submission.id}`);
            window.location.reload();
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
        }
    }

    return <>
        <div className={styles.container}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                {/* {
                    submission?.status == 'в ожидании' ? <AiOutlineFieldTime className={styles.icon} /> : submission?.status == 'выполнено' && <AiOutlineCheckCircle className={styles.icon} />
                } */}

                <div className={styles.info_container}>
                    <div className={styles.flex_container}>
                        <p className={styles.title}><span>Заявка</span> #{submission?.id}</p>
                        <p className={styles.time}>{ }</p>
                    </div>
                    <p className={styles.desc}>{submission.contentType == 'white' ? `Id канала: ${submission?.whiteContent?.channelId}` : `трек: ${submission?.grayContent?.track?.originalName}`}</p>
                    <p className={styles.desc}>Пользователь: <span>{submission?.user.contactInfo?.telegram}</span></p>
                    <p className={styles.desc}>Реферал: <span>{submission?.user?.referrer?.nick ?? 'нету'}</span></p>
                    {submission.status == EReqStatus.approved && <p className={styles.desc}>Выплачено: <span>{submission?.amount}₽</span></p>}
                </div>
                <div className={`${styles.status} ${submission?.status == 'выполнено' ? `${styles.complete}` : submission.status == 'отклонено' && `${styles.denided}`}`}>{submission?.status}</div>
            </div>

            {
                submission.status == EReqStatus.requested && <div className={styles.btn_container}>
                    <Button className={styles.approve} onClick={() => setOpenModal(!openModal)}>Подтвердить</Button>
                    <Button className={styles.denided} onClick={() => rejectedSubmission()}>Отклонить</Button>
                </div>
            }
        </div>
        {
            openModal && <ApproveSubmissionModal item={submission} onClose={() => setOpenModal(!openModal)} />
        }
    </>

}