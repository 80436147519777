import { FC, useState } from "react";
import { parseError, uploadFile } from "../../utils/utils";
import { Button, Upload, UploadFile, notification } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import styles from './UploadButton.module.scss';

export const UploadButton: FC = () => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const onUploadSuccess = async (options: any) => {
        try {
            await uploadFile(options);
            setFileList([]);
            window.location.reload();
        } catch (err) {
            console.log(parseError(err));
            notification.error({
                message: "Ошибка!",
                description: "Не удалось загрузить файл",
                duration: 2,
            });
        }
    };

    const props = {
        multiple: true 
    }

    return (
        <Upload
            {...props}
            customRequest={onUploadSuccess}
            fileList={fileList}
            onChange={({ fileList }) => setFileList(fileList)}
            
            >
            <Button type="primary" icon={<CloudUploadOutlined />} size='middle' className={styles.upload_button}>
                Загрузить трек
            </Button>
        </Upload>
    );
};