import { Button, Input, Spin } from 'antd';
import { PostItem } from '../../components/PostItem/PostItem';
import { MainLayoutComponent } from '../../components/layouts/MainLayoutComponent/MainLayoutComponent';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getPosts } from '../../redux/slices/posts';
import styles from './Blog.module.scss';
import { FC, useEffect } from 'react';
import { MdPostAdd } from "react-icons/md";
import { useNavigate } from 'react-router-dom';



export const BlogPage: FC = () => {

    const navigate = useNavigate();
    const { posts, isLoading } = useAppSelector((state) => state.posts);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getPosts());
    }, []);

    return <>
        <MainLayoutComponent>
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Посты</h1>

                    <div className={styles.tracks_count_container}>
                        <p>посты: {posts.length}</p>
                        <MdPostAdd size={25} color={'black'} />
                    </div>
                </div>

                <div className={styles.search_container}>
                    <div className={styles.input_search_container}>
                        <Input placeholder="Поиск поста по названию" size='middle' style={{ maxWidth: '250px' }} />
                        {
                            isLoading && <Spin />
                        }
                    </div>

                    <Button onClick={() => navigate('/create_post')}>Создать</Button>
                </div>

                <div className={styles.items_container}>
                    {
                        posts?.map((post) => {
                            return <PostItem post={post} />
                        })
                    }
                </div>
            </div>
        </MainLayoutComponent>
    </>

}