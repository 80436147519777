import { FC, useState, useEffect } from 'react';
import styles from './AuthComponent.module.scss';
import { Button, Space, notification } from 'antd';
import Input from 'antd/es/input/Input';
import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useNavigate } from 'react-router';
import paxios, { parseError } from '../../utils/utils';


export const AuthComponent: FC = () => {

    const dispatch = useAppDispatch();
    const { loading, isLogged } = useAppSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        nick: 'admin',
        password: '12345678'
    });

    const [passowrdVisible, setPasswordVisible] = useState();

    // useEffect(() => {
    //     isLogged && navigate('/');
    // },[isLogged]);

    const updateValues = (e: React.ChangeEvent<HTMLInputElement>) => setValues({ ...values, [e.target.name]: e.target.value });

    const postLogin = async () => {
        try {
            const { data } = await paxios.post('auth/login', values);
            localStorage.setItem('token', data.token);
            navigate('/');
        } catch (error) {
            notification.error({ message: parseError(error) });
        }
    }

    return <>
        <div className={styles.auth_container}>
            <form className={styles.form_container}>

                <Space className={styles.header}>
                    <h1>ЛОГОТИП</h1>
                    <p>Вход в админ панель...</p>
                </Space>

                <Space direction='vertical' size={'middle'}>
                    <Input
                        name='nick'
                        value={values.nick}
                        placeholder='Введите ник'
                        onChange={(e) => updateValues(e)} />
                    <Input
                        name='password'
                        value={values.password}
                        placeholder='Введите пароль'
                        // visibilityToggle={{ visible: passowrdVisible, onVisibleChange: setPasswordVisible }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateValues(e)} />
                </Space>

                <Button type='primary' onClick={() => postLogin()} loading={loading}>Войти</Button>

            </form>
        </div>
    </>
}