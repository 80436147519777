import { PropsWithChildren } from 'react';
import { Header } from '../../Header/Header';
import styles from './MainLayoutComponent.module.scss';
import AdminMenu from '../../Menu/Menu';
import { useAppSelector } from '../../../hooks/hooks';
import { CreateUserModal } from '../../modals/CreateUserModal/CreateUserModal';


export const MainLayoutComponent: React.FC<PropsWithChildren<unknown>> = ({ children }) => {

    const { isShow } = useAppSelector((state) => state.modal);

    return <>
        <div className={styles.main_layout_component}>
            <Header />
            <div className={styles.other_container}>
                {/* <img src={mainImg} className={styles.main_img} /> */}
                <AdminMenu />
                {children}
                {
                    isShow && <CreateUserModal />
                }
            </div>
        </div>
    </>

}