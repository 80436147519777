import { FC, useState } from 'react';
import styles from './ApproveSubmissionModal.module.scss';
import { Button, Input, notification } from 'antd';
import { IoClose } from "react-icons/io5";
import { IApproveContentDto, IReq } from '../../../types/types';
import paxios, { parseError } from '../../../utils/utils';


interface ApproveSubmissionsModalProps {
    item: IReq,
    onClose: () => void
}


export const ApproveSubmissionModal: FC<ApproveSubmissionsModalProps> = ({ item, onClose }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [values, setValues] = useState<IApproveContentDto>({
        userId: item.user.id,
        amount: 0,
        commercialViews: 0,
    });

    const updateValues = (e: React.ChangeEvent<HTMLInputElement>) => { setValues({ ...values, [e.target.name]: e.target.value }) }

    const approveSubmission = async () => {
        setIsLoading(true);
        try {
            await paxios.post(`receiving-funds/approve/${item.id}`, values);
            setIsLoading(false);
            window.location.reload();
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
            setIsLoading(false);
        }
    }

    return <>
        <div className={styles.container} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <p className={styles.title}>Подтверждение заявки</p>

                <div className={styles.close_icon_container}>
                    <IoClose size={25} color='black' className={styles.close_icon} onClick={onClose}/>
                </div>

                <div className={styles.info_container}>
                    <p><span>ID заявки: </span>{item.id}</p>
                    <p><span>Id канала: </span>{item.whiteContent.channelId}</p>
                    <p><span>Пользователь: </span>{item.user.contactInfo?.telegram}</p>
                </div>

                <div className={styles.input_container}>
                    <div className={styles.item}>
                        <div>Коммерческие просмотры:</div>
                        <Input placeholder='Коммерческие просмотры' name='commercialViews' onChange={(e) => updateValues(e)} type='number' />
                    </div>
                    <div className={styles.item}>
                        <div>Заработанная сумма:</div>
                        <Input placeholder='Сумма' name='amount' onChange={(e) => updateValues(e)} type='number' />
                    </div>
                </div>

                <Button type='primary' loading={isLoading} onClick={() => approveSubmission()}>Подтвердить</Button>
            </div>
        </div>
    </>

}