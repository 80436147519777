import {FC} from 'react';
import styles from './ReferralItem.module.scss';
import { IReferral } from '../../types/types';
import { LuUsers } from "react-icons/lu";



interface IRerferralItemProps {
    referral: IReferral
}

export const ReferralItem: FC<IRerferralItemProps> = ({referral}) => {
    
    console.log(referral);

    return <>
        <div className={styles.container}>
            <div className={styles.referral_container}>
                <LuUsers size={25} color='black'/>

                <div className={styles.info_referral}>
                    <p className={styles.title}>{referral.referral.nick}</p>
                    <p className={styles.desc}>id: {referral.referral.id}</p>
                </div>
            </div>
        </div>
    </>

}