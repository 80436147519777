import { MainLayoutComponent } from '../../components/layouts/MainLayoutComponent/MainLayoutComponent';
import styles from './CreateBlog.module.scss';
import { FC, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ICreatePostDto } from '../../redux/dto/dto';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Button, Input, notification } from 'antd';
import { getAllSections, postCreate } from '../../redux/slices/posts';


const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false], font: [], size: [] }],
        [{ font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
        ],
        ['link', 'video']
    ]
}

export const CreateBlogPage: FC = () => {

    const dispatch = useAppDispatch();
    
    const { isCreated } = useAppSelector((state) => state.posts);
    const {sections} = useAppSelector((state) => state.posts); 

    useEffect(() => {
        dispatch(getAllSections());
    },[]);

    const [values, setValues] = useState<ICreatePostDto>({
        preview: 'https://i.imgur.com/e2Zhvb9.jpeg',
        title: '',
        body: ''
    });

    const [sectionId, setSectionId] = useState<number>(0);

    const updateBody = (text: string) => setValues({ ...values, 'body': text });
    const updateValues = (e: React.ChangeEvent<HTMLInputElement>) => setValues({ ...values, [e.target.name]: e.target.value });

    const clickSave =  () => {
        if(sectionId == 0){
            notification.error({message: 'Пожалуйста выберите секцию для поста!', duration: 3});
            return;
        }
        dispatch(postCreate({sectionId, dto: values}))    
    }

    return <>
        <MainLayoutComponent>
            <div className={styles.container}>
                <div className={styles.inputs_container}>
                    <Input placeholder='Ссылка на фото заголовка imgur' name='preview' onChange={(e) => updateValues(e)} value={values.preview} />
                    <Input placeholder='Заголовок' name='title' onChange={(e) => updateValues(e)} />
                </div>

                <div className={styles.sections_container}>
                    {
                        sections?.map((section) => {
                            return <div className={`${styles.item} ${section.id == sectionId && styles.active}`} key={section.id} onClick={() => setSectionId(section.id)}>{section.name}</div>
                        })
                    }
                </div>

                <div className={styles.editor_container}>
                    <ReactQuill
                        theme='snow'
                        value={values.body}
                        onChange={(e) => updateBody(e)}
                        className={styles.editor_input}
                        modules={modules} />

                    <Button onClick={() => clickSave()} loading={isCreated}>Сохранить</Button>
                </div>

            </div>
        </MainLayoutComponent>
    </>

}