import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITrack } from "../../types/types"
import paxios, { parseError } from "../../utils/utils";
import { notification } from "antd";

interface ITracksState {
    tracks: Array<ITrack>;
    isLoading: boolean;
    currentTrack: ITrack | null,
}

const initialState: ITracksState = {
    tracks: [],
    currentTrack: null,
    isLoading: false
}

export const getAllTracks = createAsyncThunk(
    'tracks',
    async () => {
        try {
            const { data } = await paxios.get('track/withUsers');
            return data as Array<ITrack>;
        } catch (error) {
            console.log(parseError(error));
        }
    }
);

export const deleteTrackById = createAsyncThunk(
    'delete track',
    async (trackId: number) => {
        try{
            await paxios.delete(`/track/delete_by_id/${trackId}`);
        }catch(error){
            notification.error({message: parseError(error), duration: 3});
        }
    }
);

export const getTrackInfoById = createAsyncThunk(
    'track info by id',
    async(trackId: number) => {
        try {
            const {data} = await paxios.get(`/track/infoById/${trackId}`);
            return data;
        } catch (error) {
            notification.error({message: parseError(error), duration: 3});
        }
    }
);

export const getTracksByName = createAsyncThunk(
    'get tracks by name',
    async(name: string) => {
        try {
            const {data} = await paxios.get(`/track/byName?name=${name}`);
            return data;
        } catch (error) {
            notification.error({message: parseError(error), duration: 3});
        }
    }
);

export const tracksSlice = createSlice({
    name: 'tracks',
    initialState, 
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllTracks.pending, (state) => {
            state.isLoading = true;
        }),
        builder.addCase(getAllTracks.fulfilled, (state, action) => {
            state.isLoading = false;
            if(action.payload != undefined){
                state.tracks = action.payload;
            }
        }),
        builder.addCase(getAllTracks.rejected, (state) => {
            state.isLoading = false;
        }),


        builder.addCase(deleteTrackById.fulfilled, () => {
            //state.isLoading = false;
            //notification.success({message: 'Трек был успешно удалён!', duration: 5});
            window.location.reload();
        }),
        builder.addCase(deleteTrackById.rejected, () => {
            //state.isLoading = true;
        }),
    

        builder.addCase(getTrackInfoById.pending, (state) => {
            state.isLoading = true;
        }),
        builder.addCase(getTrackInfoById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.currentTrack = action.payload;
        }),
        builder.addCase(getTrackInfoById.rejected, (state) => {
            state.isLoading = false;
        }),


        builder.addCase(getTracksByName.pending, (state) => {
            state.isLoading = true;
        }),
        builder.addCase(getTracksByName.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tracks = action.payload;
        }),
        builder.addCase(getTracksByName.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export default tracksSlice.reducer;