import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../types/types";
import paxios, { parseError } from "../../utils/utils";
import { CreateUserDto } from "../dto/dto";
import { notification } from "antd";

interface IUsersState {
    users: Array<IUser>,
    currentUser: IUser,
    isLoading: boolean,
    isCreateLoading: boolean
}

const initialState: IUsersState = {
    users: [],
    currentUser: {} as IUser,
    isLoading: false,
    isCreateLoading: false
}

export const getAllUsers = createAsyncThunk(
    'users',
    async () => {
        try {
            const { data } = await paxios('users/all');
            return data as Array<IUser>;
        } catch (error) {
            console.log(parseError(error));
        }
    }
);

export const getUsersByParams = createAsyncThunk(
    'users by params',
    async (value: string) => {
        try {
            const { data } = await paxios(`users/params?nick=${value}&role=${value}`);
            return data as Array<IUser>;
        } catch (error) {
            console.log(parseError(error));
        }
    }
);

export const createNewUser = createAsyncThunk(
    'create new user',
    async (dto: CreateUserDto) => {
        try {
            await paxios.post('/auth/registration', dto);
            notification.success({ message: 'Аккаунт успешно создан!', duration: 2 });
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
        }
    }
);


export const getCurrentUser = createAsyncThunk(
    'get info current user',
    async (userId: number) => {
        try {
            const { data } = await paxios.get<IUser>(`users/byId/${userId}`);
            return data;
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
        }
    }
);

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllUsers.pending, (state) => {
            state.isLoading = true;
        }),
            builder.addCase(getAllUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.users = action.payload : state.users = [];
            }),
            builder.addCase(getAllUsers.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(getUsersByParams.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(getUsersByParams.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.users = action.payload : state.users = [];
            }),
            builder.addCase(getUsersByParams.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(createNewUser.pending, (state) => {
                state.isCreateLoading = true;
            }),
            builder.addCase(createNewUser.fulfilled, (state) => {
                state.isCreateLoading = false;
            }),
            builder.addCase(createNewUser.rejected, (state) => {
                state.isCreateLoading = false;
            }),


            builder.addCase(getCurrentUser.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(getCurrentUser.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.currentUser = action.payload : '';
            }),
            builder.addCase(getCurrentUser.rejected, (state) => {
                state.isLoading = false;
            })
    }
});


export default usersSlice.reducer;