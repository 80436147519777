import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getCurrentUser } from '../../redux/slices/users';
import styles from './CurrentUserPage.module.scss';
import { FC, useEffect } from 'react';
import { MainLayoutComponent } from '../../components/layouts/MainLayoutComponent/MainLayoutComponent';


export const CurrentUserPage: FC = () => {

    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((state) => state.users);

    useEffect(() => {
        if (id != undefined) {
            dispatch(getCurrentUser(+id));
        }
    }, [id]);

    //console.log(currentUser);

    return <>
        <MainLayoutComponent>
            <div className={styles.container}>
                <h1>Информация о пользователе: <span>{currentUser.nick}</span></h1>
                
                <div className={styles.infos_container}>
                    <div className={styles.basic_info}>
                        <p>ID: <span>{currentUser.id}</span></p>
                        <p>Роль: <span>{currentUser.role}</span></p>
                        <p>Баланс: <span>{currentUser.balance}₽</span></p>
                        <p>Дата регистрации: <span>{currentUser.created_at}</span></p>
                        <p>Реферальный код: <span>{currentUser.referralCode}</span></p>
                        <p>Пригласил: <span>{currentUser.referrer?.nick ?? 'админ'}</span></p>
                        <p>Количетсво рефералов: <span>{currentUser?.referrals?.length ?? 0}</span></p>
                        <p>Количетсво транзакций: <span>{currentUser?.transactions?.length ?? 0}</span></p>
                        <p>Количетсво треков: <span>{currentUser?.chains?.length ?? 0}</span></p>
                        <p>Количетсво счетов для выплаты: <span>{currentUser?.paymentMethod?.length}</span></p>
                    </div>
                </div>
            </div>
        </MainLayoutComponent>
    </>

}