import { FC, useEffect, useMemo, useState } from 'react';
import { ContainerForPages } from '../../components/ContainerForPages/ContainerForPages';
import { AiFillYoutube } from "react-icons/ai";
import { useAppDispatch, useAppSelector, useDebounce } from '../../hooks/hooks';
import { getAllVideo, getVideoRejected, getVideoRequested, getVideosPaid } from '../../redux/slices/video';
import styles from './VideoPage.module.scss';
import { VideoItem } from '../../components/VideoItem/VideoItem';
import { useParams } from 'react-router-dom';
import { EVideoPaymentStatus } from '../../utils/enums';
import { PaymentVideoItem } from '../../components/PaymentVideoItem/PaymentVideoItem';

export const VideoPage: FC = () => {

    const { status } = useParams();

    const [searchValue, setSerchValue] = useState<string>('');
    const [title, setTitle] = useState('Видео');

    const dispatch = useAppDispatch();
    const { videos, isLoading } = useAppSelector((state) => state.video);

    const debouced = useDebounce(searchValue, 500);

    useMemo(() => {
        if (status == EVideoPaymentStatus.requested) {
            setTitle('Запросы на выплату');
            dispatch(getVideoRequested());
        }

        if (status == EVideoPaymentStatus.paid) {
            setTitle('Завершенные выплаты');
            dispatch(getVideosPaid());
        }

        if (status == EVideoPaymentStatus.rejected) {
            setTitle('Отклоненные выплаты');
            dispatch(getVideoRejected());
        }

        if (status == undefined) {
            setTitle('Видео');
            dispatch(getAllVideo());
        }
    }, [status]);

    useEffect(() => {

    }, [debouced]);

    return <>
        <ContainerForPages
            searchTitle='Поиск видео по параметрам'
            isLoading={isLoading}
            title={title}
            count={videos.length}
            icon={<AiFillYoutube size={25} color='black' />}
            inputChange={(e) => setSerchValue(e.target.value)}>
            <div className={styles.videos_container}>
                {
                    videos?.map((video) => {
                        if (status == EVideoPaymentStatus.requested) {
                            return <PaymentVideoItem video={video} key={video.id} />
                        }

                        if (status == undefined) {
                            return <VideoItem video={video} key={video.id} />
                        }
                    })
                }
            </div>
        </ContainerForPages>
    </>

}