import { FC, useEffect, useState, ChangeEvent } from 'react';
import { MainLayoutComponent } from '../../components/layouts/MainLayoutComponent/MainLayoutComponent';
import styles from './Tracks.module.scss';
import { Input, Spin } from 'antd';
import { TrackItem } from '../../components/TrackItem/TrackItem';
import { useAppDispatch, useAppSelector, useDebounce } from '../../hooks/hooks';
import { RootState } from '../../redux/store';
import { UploadButton } from '../../components/UploadButton/UploadButton';
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import { getAllTracks, getTracksByName } from '../../redux/slices/tracks';

export const TracksPage: FC = () => {

    const dispatch = useAppDispatch();
    const { tracks, isLoading } = useAppSelector((state: RootState) => state.tracks);

    const [inputValue, setInputValue] = useState('');
    const debouncedValue = useDebounce(inputValue, 500);

    useEffect(() => {
        dispatch(getAllTracks());
    }, []);

    useEffect(() => {
        dispatch(getTracksByName(debouncedValue));
    }, [debouncedValue]);

    const inputHandler = (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);

    return <>
        <MainLayoutComponent>
            <div className={styles.track_container}>
                <div className={styles.header}>
                    <h1>Треки</h1>

                    <div className={styles.tracks_count_container}>
                        <p>{tracks.length} треков</p>
                        <PiSoundcloudLogoDuotone size={25} color={'black'} />
                    </div>
                </div>

                <div className={styles.search_container}>
                    <div className={styles.input_search_container}>
                        <Input placeholder="Поиск трека по названию" size='middle' style={{ maxWidth: '250px' }} onChange={(e) => inputHandler(e)}/>
                        {
                            isLoading && <Spin />
                        }
                    </div>
                    <UploadButton />
                </div>

                <div className={styles.tracks_container}>
                    {
                        tracks?.length > 0 && tracks.map((track) => {
                            return <TrackItem track={track} key={track.id} />
                        })
                    }
                </div>
            </div>
        </MainLayoutComponent>
    </>

}