import { ChangeEvent, useEffect, useState, FC } from "react";
import { UserItem } from "../../components/UserItem/UserItem";
import { MainLayoutComponent } from "../../components/layouts/MainLayoutComponent/MainLayoutComponent"
import { useAppDispatch, useAppSelector, useDebounce } from "../../hooks/hooks";
import { RootState } from "../../redux/store";
import styles from './User.module.scss';
import { getAllUsers, getUsersByParams } from "../../redux/slices/users";
import { FiUsers } from "react-icons/fi";
import { Input, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";


export const UsersPage: FC = () => {

    const dispatch = useAppDispatch();
    const { users, isLoading } = useAppSelector((state: RootState) => state.users);

    const [inputValue, setInputValue] = useState<string>('');
    const debouncedValue = useDebounce<string>(inputValue, 500);

    const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    }

    useEffect(() => {
        dispatch(getAllUsers());
    }, []);

    useEffect(() => {
        dispatch(getUsersByParams(debouncedValue));
    }, [debouncedValue]);

    return <>
        <MainLayoutComponent>
            <div className={styles.user_container}>

                <div className={styles.header}>
                    <div>
                        <h1>Пользователи</h1>
                        {/* <p>Описание</p> */}
                    </div>

                    <div className={styles.users_count_container}>
                        <p>{users.length} чел.</p>
                        <FiUsers size={25} color={'black'} />
                    </div>
                </div>

                <div className={styles.search_container}>
                    <Input placeholder="Поиск пользователя по параметрам" onChange={(e) => handleSearchInput(e)} prefix={<SearchOutlined />} style={{ maxWidth: '300px' }} size='middle' />
                    {
                        isLoading && <Spin/>
                    }
                </div>

                <div className={styles.users_container}>
                    {
                        users?.map((item) => {
                            return <UserItem user={item} key={item.id} />
                        })
                    }
                </div>
            </div>
        </MainLayoutComponent>
    </>

}