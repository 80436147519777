import { FC, useState, useRef } from 'react';
import styles from "./CustomPlayer.module.scss";
import { BiVolumeFull } from "react-icons/bi";
import { AiOutlinePlayCircle, AiOutlinePauseCircle} from "react-icons/ai";
import { TRACK_BASE_URL } from '../../utils/utils';

interface PlayerProps {
    name: string,
    url: string
}

export const CustomPlayer: FC<PlayerProps> = ({name, url}) => {

    const [play, setPlay] = useState(false);
    const palyerRef = useRef<HTMLAudioElement>(null);
    const MAX = 20;

    const toggleAudio = () => {
        if (play) {
            palyerRef.current?.pause();
            setPlay(false);
        } else {
            palyerRef.current?.play();
            setPlay(true);
        }
    }

    const handleVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const volume = Number(value) / MAX;
        if (palyerRef.current != null) {
            palyerRef.current.volume = volume;
        }
    }

    return <>
        <div className={styles.custom_player_container}>
            <div className={styles.custom_track_header} onClick={() => toggleAudio()}>
                <p>{name}</p>
                {
                    play ? <AiOutlinePauseCircle size={20} color='black'/> : <AiOutlinePlayCircle size={20} color='black'/> 
                }
            </div>
            <div className={styles.volume_container}>
                <input type='range' min={0} max={MAX} onChange={(e) => handleVolume(e)} />
                <BiVolumeFull size={20} color='black'/>
            </div>
        </div>
        <audio ref={palyerRef} loop src={`${TRACK_BASE_URL}${url}`} />
    </>
}