import { Button } from 'antd';
import styles from './UserItem.module.scss';
import { BiUser } from "react-icons/bi";
import { IUser } from '../../types/types';
import { CustomPlayer } from '../CustomPlayer/CustomPlayer';
import { useEffect, useState } from 'react';
import { ReferralItem } from '../ReferralItem/ReferralItem';
import { useNavigate } from 'react-router-dom';


interface IUserItemProps {
    user: IUser
}

export const UserItem: React.FC<IUserItemProps> = ({ user }) => {

    const navigate = useNavigate();

    const [isViewTrack, setIsViewTrack] = useState(false);
    const [isViewReferral, setIsViewReferral] = useState(false);

    return <>
        <div className={styles.user_item_main}>

            <div className={styles.user_item_container}>
                <div className={styles.user_item_info}>
                    <BiUser size={30} color={'black'} />
                    <div>
                        <div className={styles.nick} onClick={() => navigate(`/user/${user.id}`)}>{user?.nick}</div>
                        <div className={styles.id}>{user.contactInfo?.telegram ?? "нету"}</div>
                        <div className={styles.id}>ID: {user?.id}</div>
                        {
                            user.role == 'USER' && <>
                                <div className={styles.id}>Баланс: {user?.balance}₽</div>
                                <div className={styles.id}>Код: {user.referralCode}</div>
                                <div className={styles.id}>Кол-во треков: {user.chains?.length}</div>
                                <div className={styles.id}>Рефералы: {user?.referrals?.length}</div>
                                <div className={styles.id}>Транзакции: {user?.transactions?.length}</div>
                                {/* <div className={styles.id}>Кол-во видео: {user.chains?.length}</div> */}

                            </>
                        }
                        <div className={styles.id}>Дата: {user?.created_at}</div>

                    </div>
                </div>
                <div>
                    {
                        user.role == 'USER' ?
                            <div className={styles.status}>{user.role}</div> :
                            <div className={styles.admin_status}>{user.role}</div>
                    }
                </div>
            </div>

            {
                user.role == 'USER' &&
                <div className={styles.user_item_btn_container}>
                    {
                        user.chains?.length > 0 &&
                        <Button type='primary' size={'small'} onClick={() => setIsViewTrack(!isViewTrack)}>Треки {user.chains?.length}</Button>
                    }
                    <Button type='primary' size={'small'}>Видео</Button>
                    {
                        user.referrals?.length > 0 && <Button type='primary' size={'small'} onClick={() => setIsViewReferral(!isViewReferral)}>Рефералы {user.referrals?.length}</Button>
                    }
                </div>
            }

            {
                isViewTrack && user.chains?.length > 0 &&
                <div className={styles.auido_container}>
                    {
                        user.chains?.length >= 1 && user.chains.map((chain) => {
                            return <CustomPlayer name={chain?.track.originalName} url={chain?.track.fileName} key={chain.id} />
                        })
                    }
                </div>
            }

            {
                isViewReferral && user.referrals.length > 0 &&
                <div className={styles.referral_container}>
                    {
                        user.referrals?.length >= 1 && user.referrals.map((referral) => {
                            return <ReferralItem referral={referral} key={referral.id} />
                        })
                    }
                </div>

            }

        </div>
    </>

}