import { IVideo } from "../../types/types";
import styles from "./VideoItem.module.scss";
import { FC, useEffect, useState } from 'react';
import { youtubeAxios } from "../../utils/utils";
import { IYouTube } from "../../utils/youtube";
import { Button, Spin, notification } from "antd";

interface IVideoItemProps {
    video: IVideo
}

export const VideoItem: FC<IVideoItemProps> = ({ video }) => {

    const [ytData, setYtData] = useState<IYouTube>();
    const [isLoading, setIsLoading] = useState(false);

    let videoId = video.link.split("?")[1].split("=")[1];

    useEffect(() => {
        test();
    }, []);

    const test = async () => {
        setIsLoading(true);
        try {
            const { data } = await youtubeAxios.get(`/videos?part=snippet&id=${videoId}`);
            setYtData(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            notification.error({ message: `Данные из ${video.link} не удалось получить!` });
        }
    }

    return <>
        <div className={styles.video_item_container}>
            <div className={styles.container}>
                {
                    !isLoading ? <>
                        <div className={styles.video_item}>
                            <img src={`${ytData?.items[0].snippet.thumbnails.default.url}`} />
                            <div>
                                <div className={styles.title} onClick={() => {
                                    window.open(`${video.link}`)
                                }}>{ytData?.items[0].snippet.title}</div>
                                <div className={styles.other}><span>Просмотры:</span> {ytData?.items[0].statistics.viewCount}</div>
                                <div className={styles.other}><span>Сохраненные просмотры:</span> {video.recentViews}</div>
                                <div className={styles.other}><span>Канал:</span> {ytData?.items[0].snippet.channelTitle}</div>
                                <div className={styles.other}><span>Ник:</span> {}</div>
                                <div className={styles.other}><span>ID трека: </span>{}</div>
                            </div>

                            <Button>Удалить</Button>
                        </div>
                    </> : <Spin />
                }
            </div>
        </div>
    </>

}