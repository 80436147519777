import styles from './Header.module.scss';
import { BiUser } from "react-icons/bi";


export const Header: React.FC = () => {

    return <>
        <div className={styles.header_container}>
            <div className={styles.items_container}>
                <div className={styles.logo} />

                <div className={styles.account_info}>
                    <div>
                        <p className={styles.user_nick}>Admin</p>
                        <p className={styles.user_id}>Идентификатор: 234324</p>
                    </div>
                    <BiUser size={30} color={'black'}/>
                </div>
            </div>
        </div>
    </>

}