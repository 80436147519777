import { configureStore } from "@reduxjs/toolkit";
import auth from './slices/auth';
import users from './slices/users';
import tracks from './slices/tracks';
import modal from "./slices/modal";
import video from "./slices/video";
import submissions from "./slices/submissions";
import posts from "./slices/posts";


export const store = configureStore({
    reducer: {
        auth,
        users,
        tracks,
        modal,
        video,
        submissions,
        posts
    },
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;