import axios from "axios";

export const paxios = axios.create({
    baseURL: 'https://nestjs-production-5e21.up.railway.app/',
    // baseURL: 'http://localhost:5000/',
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});

export const youtubeAxios = axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3',
    params: {
        part: 'statistics',
        maxResults: 5,
        key: 'AIzaSyAwlEWMoK7tr3hFqDY8phc-9VePMexQQ9M'
    }
})

export default paxios;

export const parseError = (e: any) => {
    const messages = JSON.parse(e.request.response).message;
    if (Array.isArray(messages)) {
        return messages.join(' ');
    }
    return messages;
}

export const TRACK_BASE_URL = 'https://nestjs-production-5e21.up.railway.app/uploads_tracks/';

export const uploadFile = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.get('token')}`,
            "Content-Type": "multipart/form-data"
        },
        onProgress: (event: ProgressEvent) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
        },
    };
    try {
        const { data } = await axios.post('https://nestjs-production-5e21.up.railway.app/track/upload_track', formData, config);
        onSuccess();
        return data;
    } catch (err) {
        console.log(err);
        onError({ err });
    }
};