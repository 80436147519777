import { FC, useRef, useState } from 'react';
import styles from './TrackItem.module.scss';
import { BsFileEarmarkMusic } from "react-icons/bs";
import { ITrack } from '../../types/types';
import { TRACK_BASE_URL } from '../../utils/utils';
import { IoVolumeLowOutline } from "react-icons/io5";
import { AiOutlinePlayCircle, AiOutlinePauseCircle, AiOutlineDelete} from "react-icons/ai";
import { notification } from 'antd';
import { deleteTrackById } from '../../redux/slices/tracks';
import { useAppDispatch } from '../../hooks/hooks';
import { useNavigate } from 'react-router';


interface ITrackItemProps {
    track: ITrack
}

export const TrackItem: FC<ITrackItemProps> = ({ track }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [play, setPlay] = useState(false);
    const palyerRef = useRef<HTMLAudioElement>(null);
    const MAX = 20;

    const toggleAudio = () => {
        if (play) {
            palyerRef.current?.pause();
            setPlay(false);
        } else {
            palyerRef.current?.play();
            setPlay(true);
        }
    }

    const handleVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const volume = Number(value) / MAX;
        if (palyerRef.current != null) {
            palyerRef.current.volume = volume;
        }
    }

    const copyCode = () => {
        navigator.clipboard.writeText(track.code);
        notification.success({message: 'Код скопирован!', duration: 1});
    }

    const deleteTrack = () => {
        dispatch(deleteTrackById(track.id));
    }

    return <>
        <div className={styles.track_item_main}>
            <div className={styles.track_item_container}>
                <div className={styles.track_delete_container} onClick={() => deleteTrack()}>
                    <AiOutlineDelete color={'red'} size={25}/>
                </div>
                <div className={styles.track_item_info}>
                    <BsFileEarmarkMusic size={30} color={'black'} />
                    <div>
                        <div className={styles.name} onClick={() => navigate(`/track/${track.id}`)}>{track.originalName}</div>
                        <div className={styles.code} onClick={() => copyCode()}>Код: {track.code}</div>
                        <div className={styles.other}>Пользователи: {}</div>
                        <div className={styles.other}>Видео: {}</div>
                    </div>
                </div>
            </div>

            <div className={styles.player_container}>
                <audio ref={palyerRef} loop src={`${TRACK_BASE_URL}${track.fileName}`} />
                <div style={{display:'flex', alignItems: 'center', gap: '10px'}}>
                    <IoVolumeLowOutline size={25} color={'black'}/>
                    <input type='range' min={0} max={MAX} onChange={(e) => handleVolume(e)} />
                </div>

                <div onClick={() => toggleAudio()} style={{height: 'fit-content'}}>
                    {play ? <AiOutlinePauseCircle size={25} color='black'/> : <AiOutlinePlayCircle size={25} color='black'/>}
                </div>
            </div>
        </div>
    </>

}