import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IVideo } from "../../types/types";
import { notification } from "antd";
import paxios, { parseError } from "../../utils/utils";

interface IVideoState {
    videos: Array<IVideo>;
    isLoading: boolean;
}

const initialState: IVideoState = {
    videos: [],
    isLoading: false
}

export const getAllVideo = createAsyncThunk(
    'get all video',
    async() => {
        try {
            const {data} = await paxios.get(`/videolinks/all`);
            return data as Array<IVideo>;
        } catch (error) {
            notification.error({message: parseError(error)});
        }
    }
);

export const getVideoRequested = createAsyncThunk(
    'get videos requested',
    async() => {
        try {
            const {data} = await paxios.get('/videolinks/requested');
            return data as Array<IVideo>;
        } catch (error) {
            notification.error({message: parseError(error)});
        }
    }
);

export const getVideosPaid = createAsyncThunk(
    'get videos paid',
    async() => {
        try {
            const {data} = await paxios.get('/videolinks/paid');
            return data as Array<IVideo>;
        } catch (error) {
            notification.error({message: parseError(error)});
        }
    }
);

export const getVideoRejected = createAsyncThunk(
    'get videos rejected',
    async() => {
        try {
            const {data} = await paxios.get('/videolinks/rejected');
            return data as Array<IVideo>;
        } catch (error) {
            notification.error({message: parseError(error)});
        }
    }
);

const videoSlice = createSlice({
    name: 'videos',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllVideo.pending, (state) => {
            state.isLoading = true;
        }),
        builder.addCase(getAllVideo.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.videos = action.payload : [];
        }),
        builder.addCase(getAllVideo.rejected, (state) => {
            state.isLoading = false;
        }),



        builder.addCase(getVideoRejected.pending, (state) => {
            state.isLoading = true;
            state.videos = [];
        }),
        builder.addCase(getVideoRejected.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.videos = action.payload : [];
        }),
        builder.addCase(getVideoRejected.rejected, (state) => {
            state.isLoading = false;
        }),
    


        builder.addCase(getVideosPaid.pending, (state) => {
            state.isLoading = true;
            state.videos = [];
        }),
        builder.addCase(getVideosPaid.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.videos = action.payload : [];
        }),
        builder.addCase(getVideosPaid.rejected, (state) => {
            state.isLoading = false;
        }),



        builder.addCase(getVideoRequested.pending, (state) => {
            state.isLoading = true;
            state.videos = [];
        }),
        builder.addCase(getVideoRequested.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.videos = action.payload : [];
        }),
        builder.addCase(getVideoRequested.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export default videoSlice.reducer;