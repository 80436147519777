import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IReq } from "../../types/types";
import { GetReqDto } from "../dto/dto";
import paxios, { parseError } from "../../utils/utils";
import { notification } from "antd";

interface ISubmissionsState {
    submissios: Array<IReq>,
    isLoading: boolean
}

const initialState: ISubmissionsState = {
    submissios: [],
    isLoading: false
}

export const getSubmissionsByParams = createAsyncThunk(
    'get submissions by params',
    async (dto: GetReqDto) => {
        try {
            const { data } = await paxios.get<Array<IReq>>(`receiving-funds/byParams?contentType=${dto.contentType}&status=${dto.status}`);
            return data;
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
        }
    }
);

const submissionsSlice = createSlice({
    name: 'submissions slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSubmissionsByParams.pending, (state) => {
            state.isLoading = true;
        }),
            builder.addCase(getSubmissionsByParams.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.submissios = action.payload : state.submissios = [];
            }),
            builder.addCase(getSubmissionsByParams.rejected, (state) => {
                state.isLoading = false;
            })
    }
});

export default submissionsSlice.reducer;