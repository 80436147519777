import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IToken, IUserAuth } from "../../types/types"
import paxios, { parseError } from '../../utils/utils';
import Cookies from 'js-cookie';

interface IModalState {
    isShow: boolean;
}


const initialState: IModalState = {
    isShow: false,
}

const modalSlice = createSlice({
    name: 'modal user create',
    initialState,
    reducers: {
        setShowModal:(state, action) => {
            state.isShow = action.payload;
        }
    }
});

export const {setShowModal} = modalSlice.actions;
export default modalSlice.reducer;