import { useNavigate } from 'react-router-dom';
import { IPost } from '../../types/types';
import styles from './PostItem.module.scss';
import { FC } from 'react';
import { AiOutlineEye } from "react-icons/ai";


interface IBlogItemProps {
    post: IPost
}

export const PostItem: FC<IBlogItemProps> = ({post}) => {

    const navigate = useNavigate();

    return <>
        <div className={styles.container} onClick={() => navigate(`/blog/${post.id}`)}>
            <div className={styles.preview}>
                <img src={post.preview} />
            </div>

            <div className={styles.info_container}>
                <div className={styles.title}>
                    <p>{post.title}</p>
                </div>

                <div className={styles.post_info_container}>
                    <p className={styles.date}>{}</p>

                    <div className={styles.likes_count}>
                        <div className={styles.count}>{post.viewCount}</div>
                        <AiOutlineEye size={20} />
                    </div>
                </div>

                <div className={styles.post_section}>
                    <p className={styles.section}>{post.section.name}</p>
                </div>
            </div>
        </div>
    </>

}