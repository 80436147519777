import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPost, ISection } from "../../types/types";
import paxios, { parseError } from "../../utils/utils";
import { notification } from "antd";
import { ICreatePost, ICreatePostDto } from "../dto/dto";

interface IBlogState {
    posts: Array<IPost>,
    isLoading: boolean,
    currentPost: IPost,
    isCreated: boolean,
    sections: Array<ISection>
}

const initialState: IBlogState = {
    posts: [],
    isLoading: false,
    currentPost: {} as IPost,
    isCreated: false,
    sections: []
}


export const getPosts = createAsyncThunk(
    'get all posts',
    async () => {
        try {
            const { data } = await paxios.get<Array<IPost>>('blog/all');
            return data;
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
        }
    }
);


export const getPostById = createAsyncThunk(
    'get post by id',
    async (postId: number) => {
        try {
            const { data } = await paxios.get<IPost>(`blog/post/${postId}`);
            return data;
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
        }
    }
)


export const getPostsBySectionId = createAsyncThunk(
    'get posts by section id',
    async (sectionId: number) => {
        try {
            const { data } = await paxios.get<Array<IPost>>(`blog/postBySection/${sectionId}`)
            return data;
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
        }
    }
);

export const postCreate = createAsyncThunk(
    'create post',
    async (data: ICreatePost) => {
        try {
            await paxios.post(`blog/create/${data.sectionId}`, data.dto);
            notification.success({ message: 'Статья успешно опубликована!', duration: 3 });
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
        }
    }
);

export const getAllSections = createAsyncThunk(
    'get all sections',
    async () => {
        try {
            const { data } = await paxios.get<Array<ISection>>('blog-sections/all');
            return data;
        } catch (error) {
            alert(parseError(error));
        }
    }
);

const BlogSlice = createSlice({
    name: 'blog slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPosts.pending, (state) => {
            state.isLoading = true;
        }),
            builder.addCase(getPosts.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.posts = action.payload : state.posts;
            }),
            builder.addCase(getPosts.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(getPostsBySectionId.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(getPostsBySectionId.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.posts = action.payload : state.posts;
            }),
            builder.addCase(getPostsBySectionId.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(getPostById.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(getPostById.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.currentPost = action.payload : state.currentPost;
            }),
            builder.addCase(getPostById.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(postCreate.pending, (state) => {
                state.isCreated = true;
            }),
            builder.addCase(postCreate.fulfilled, (state) => {
                state.isCreated = false;
            }),
            builder.addCase(postCreate.rejected, (state) => {
                state.isCreated = false;
            }),


            builder.addCase(getAllSections.pending, (state) => {
                //state.isLoading = true;
            }),
            builder.addCase(getAllSections.fulfilled, (state, action) => {
                //state.isLoading = false;
                action.payload != undefined ? state.sections = action.payload : state.sections;
            }),
            builder.addCase(getAllSections.rejected, (state) => {
                //state.isLoading = false;
            })
    }
});

export default BlogSlice.reducer;